<template>
  <v-container id="production-tasks" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.tasks.title') }}
        <v-spacer />
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('admin.sub.tasks.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="headers" :options="options" :items="tasks" :search="search" :loading="loading">
        <template #[`item.userRole`]="{ item }">
          {{ $t('admin.sub.tasks.table.userRole.' + item.userRole) }}
        </template>
        <template #[`item.type`]="{ item }">
          {{ $t('admin.sub.tasks.table.type.' + item.type) }}
        </template>
        <template #[`item.priority`]="{ item }">
          {{ $t('admin.sub.tasks.table.priority.' + item.priority) }}
        </template>
        <template #[`item.name`]="{ item }">
          <span v-html="formatMarked(item.name)" />
        </template>
        <template #[`item.description`]="{ item }">
          <span v-html="formatMarked(item.description)" />
        </template>
        <template #[`item.weekdays`]="{ item }">
          <span>{{ formatWeekdays(item.weekdays) }}</span>
        </template>
        <template #[`item.skip`]="{ item }">
          <span>{{ item.skip === undefined || item.skip === null ? '' : 'Oui' }}</span>
        </template>
        <template #[`item.updatedAt`]="{ item }">
          <span>{{ formatDate(item.updatedAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { marked } from 'marked';
  import { displayISO, WEEKDAY } from '@/util/DateUtil';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'ProductionTasks',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        loading: false,
        headers: [
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.userRole'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'userRole'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.type'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'type'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.priority'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'priority'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.name'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.description'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'description'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.daysOfMonth'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'daysOfMonth'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.weekdays'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'weekdays'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.from'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'from'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.to'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'to'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.skip'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'skip'
          },
          {
            text: this.$i18n.t('admin.sub.tasks.table.column.updatedAt'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'updatedAt'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        search: '',
        tasks: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatMarked: marked,
      formatWeekdays (weekdays) {
        if (!Array.isArray(weekdays)) {
          return this.$i18n.t('common.all');
        }

        return weekdays.map((weekday) => WEEKDAY[weekday]).join(', ');
      },
      formatDate: displayISO,
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'production/tasks');
          if (response.data !== undefined && response.data.tasks !== undefined) {
            this.tasks = response.data.tasks;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      }
    }
  };
</script>
