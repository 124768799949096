var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"production-tasks","fluid":"","tag":"section"}},[_c('alert-notification',{attrs:{"message":_vm.alert}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.sub.tasks.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('admin.sub.tasks.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.tasks,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.userRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('admin.sub.tasks.table.userRole.' + item.userRole))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('admin.sub.tasks.table.type.' + item.type))+" ")]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('admin.sub.tasks.table.priority.' + item.priority))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatMarked(item.name))}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatMarked(item.description))}})]}},{key:"item.weekdays",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatWeekdays(item.weekdays)))])]}},{key:"item.skip",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.skip === undefined || item.skip === null ? '' : 'Oui'))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }